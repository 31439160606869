import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import { Link, useNavigate } from "react-router-dom";
import { Backdrop } from "../components/Backdrop";
import Button from "../components/Button/Button";
import CartItem from "../components/CartItem/CartItem";
import CartItemInner from "../components/CartItemInner/CartItemInner";
import CCContainer from "../components/CCContainer/CCContainer";
import InfoIcon from "../components/icons/Info";
import PencilIcon from "../components/icons/Pencil";
import PinIcon from "../components/icons/Pin";
import InputContainer from "../components/InputContainer/InputContainer";
import InputRow from "../components/InputRow/InputRow";
import ModalContentContainer from "../components/ModalContentContainer";
import OrderSummaryBox from "../components/OrderSummaryBox/OrderSummaryBox";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import RightContainer from "../components/RightContainer/RightContainer";
import ShippingAddressContainer from "../components/ShippingAddressContainer/ShippingAddressContainer";
import UspsLockerPopUpContent from "../components/UspsLockerPopUpContent/UspsLockerPopUpContent";
import UspsLockerPopUpWrapper from "../components/UspsLockerPopUpWrapper/UspsLockerPopUpWrapper";
import { Container, ContentContainer } from "../components/WebLayoutComponents";
import { firstStepSchema, formSchema } from "../constants/CheckoutFormSchema";
import { stateAbbreviations } from "../constants/selects";
import { useCart } from "../providers/CartContext";
import { Api } from "../services/Api";
import SmartLockers from "../static/img/locker-with-boxes-transparent.png";
import { UspsLockers } from "../static/sdk/usps-lockers";
import { formatCurrency } from "../utils/cartUtils";

// Import Images
import applePay from "../static/img/apple-pay.png";
import googlePay from "../static/img/g-pay.png";

function Checkout() {
  const { cart, clearCart } = useCart();
  const navigate = useNavigate();
  const api = new Api();
  api.setup();

  const lockers = useMemo(() => new UspsLockers(process.env.REACT_APP_API_KEY), []);

  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [findLockerModalOpen, setFindLockerModalOpen] = useState(false);
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const [lockerSelected, setLockerSelected] = useState(false);
  const [lockersFound, setLockersFound] = useState(false);
  const [json] = useState();
  const hideCCForm = true;
  const radiusInput = 20;
  const [validationErrors, setValidationErrors] = useState({});
  const [addressData, setAddressData] = useState({
    aptNumber: "",
    shipAddress: "",
    shipCity: "",
    shipState: "",
    shipPostal: "",
    pickupStreet: "",
    pickupCity: "",
    pickupState: "",
    pickupPostal: "",
  });
  const [noLockerServiceModal, setNoLockerServiceModal] = useState(false);

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: zodResolver(formSchema),
  });

  const watchedValues = watch();

  const updateFormValues = useCallback(
    (fieldName, value) => {
      setValue(fieldName, value);
    },
    [setValue]
  );

  const openFindLockerModal = () => {
    lockers.openLockerSelectionWindow(watchedValues.zip, radiusInput);
    setFindLockerModalOpen(true);
  };

  const onSubmit = data => {
    if (isValid) {
      clearCart();
      navigate("/confirmation", {
        state: {
          ...data,
          orderSubTotal: subtotal,
          lockerData: lockerSelected ? addressData : {},
        },
      });
    }
  };

  const formCheckForLockers = async (postalCode, radius) => {
    try {
      const hasLockers = await lockers.lockersAvailable(postalCode, radius);
      if (hasLockers) {
        setLockersFound(true);
      } else {
        setCurrentStep(2);
      }
    } catch (e) {
      setNoLockerServiceModal(true);
    }
  };

  const onSearchSubmitFromForm = () => {
    if (watchedValues.zip) {
      formCheckForLockers(watchedValues.zip, radiusInput);
    }
  };

  const selectLockerLocation = useCallback(
    location => {
      updateFormValues("streetAddress", location.deliveryAddress1);
      updateFormValues("city", location.deliveryCity);
      updateFormValues("state", location.deliveryState);
      updateFormValues("zip", location.deliveryZIP5);

      setAddressData({
        ...addressData,
        aptNumber: "",
        shipAddress: location.deliveryAddress1,
        shipCity: location.deliveryCity,
        shipState: location.deliveryState,
        shipPostal: location.deliveryZIP5,
        pickupStreet: location.locationAddress1,
        pickupCity: location.locationCity,
        pickupState: location.locationState,
        pickupPostal: location.locationZIP5,
      });

      setLockerSelected(true);
      setCurrentStep(2);
      setFindLockerModalOpen(false);
    },
    [addressData, updateFormValues]
  );

  useEffect(() => {
    const removeLockerListener = lockers.addOnLockerSelectedListener(selectLockerLocation);
    return () => {
      removeLockerListener();
    };
  }, [lockers, selectLockerLocation, setLearnMoreModalOpen, setFindLockerModalOpen]);

  useEffect(() => {
    const setDefaultCCDetails = () => {
      updateFormValues("cardNumber", "4111 1111 1111 1111");
      updateFormValues("expirationDate", "12/25");
      updateFormValues("securityCode", "123");
      updateFormValues("name", "John Doe");
    };

    if (loading) {
      setDefaultCCDetails();
      setLoading(false);
    }

    if (lockersFound && currentStep === 1 && !findLockerModalOpen) {
      setLearnMoreModalOpen(true);
    }
  }, [json, lockersFound, currentStep, findLockerModalOpen, loading, updateFormValues]);

  const steps = [{ label: "Shipping" }, { label: "Review & Payment" }, { label: "Order Received" }];

  const handleFirstStepCompletion = () => {
    const firstStepData = {
      firstName: watchedValues.firstName,
      lastName: watchedValues.lastName,
      email: watchedValues.email,
      streetAddress: watchedValues.streetAddress,
      country: watchedValues.country,
      aptNumber: watchedValues.aptNumber,
      city: watchedValues.city,
      state: watchedValues.state,
      zip: watchedValues.zip,
      phone: watchedValues.phone,
    };

    try {
      firstStepSchema.parse(firstStepData);

      setValidationErrors({});
    } catch (error) {
      console.error(error.errors);

      // Map the errors to the corresponding field names and messages
      const mappedErrors = error.errors.reduce((acc, curr) => {
        acc[curr.path[0]] = curr.message;
        return acc;
      }, {});

      // Set the validation errors state
      setValidationErrors(mappedErrors);
    } finally {
      onSearchSubmitFromForm();
    }
  };

  const subtotal = cart.items.reduce((total, item) => {
    // Extract numerical value from the price string and add it to the total
    const itemPrice = parseFloat(item.price.replace("$", "") * item.quantity);

    return total + itemPrice;
  }, 0);

  return (
    <Container>
      {findLockerModalOpen && (
        <>
          <Backdrop active={findLockerModalOpen} onClick={() => setFindLockerModalOpen(false)} />
          <ModalContentContainer
            title="Select a USPS Smart Locker in progress"
            titleWidth="85%"
            exit={() => setFindLockerModalOpen(false)}
            actions={
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  type="button"
                  variant="link"
                  onClick={() => {
                    setFindLockerModalOpen(false);
                  }}
                  className="desktop-only"
                >
                  Close
                </Button>
              </div>
            }
          >
            <div style={{ maxWidth: 393, width: "100%" }}>
              <p>
                Check for a new window popup in order to select a locker. Select a locker on the window popup to proceed
                or optionally close to proceed without selecting a locker.
              </p>
            </div>
          </ModalContentContainer>
        </>
      )}

      {learnMoreModalOpen && (
        <>
          <Backdrop active={learnMoreModalOpen} onClick={() => setLearnMoreModalOpen(false)} />
          <ModalContentContainer
            title="A USPS Smart Locker is available near the recipient's address"
            titleWidth="85%"
            exit={() => setLearnMoreModalOpen(false)}
            actions={
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  type="button"
                  form="checkout-form"
                  onClick={() => {
                    openFindLockerModal();
                    setLearnMoreModalOpen(false);
                  }}
                  className="mobile-full-width"
                >
                  Ship to Locker
                </Button>
                <Button
                  type="button"
                  variant="link"
                  onClick={() => {
                    setCurrentStep(2);
                    setLearnMoreModalOpen(false);
                  }}
                  className="desktop-only"
                >
                  No Thanks
                </Button>
              </div>
            }
          >
            <div style={{ maxWidth: 393, width: "100%" }}>
              <p>
                The address you're sending to is close to a secure, self-service USPS® Smart Locker. For no extra cost,
                you can ship your package to the USPS Smart Locker, where your recipient can pick it up. You'll just
                need to provide their email address so we can email them their pickup QR code.
              </p>
            </div>
            <div style={{ maxWidth: 290, width: "100%" }} className="desktop-only">
              <img src={SmartLockers} alt="smart-lockers" width="100%" />
            </div>
          </ModalContentContainer>
        </>
      )}

      {noLockerServiceModal && (
        <>
          <Backdrop active={noLockerServiceModal} onClick={() => setNoLockerServiceModal(false)} />
          <ModalContentContainer exit={() => setNoLockerServiceModal(false)} title="">
            <div>
              <h3>Looks like there was something wrong with reaching the lockers service.</h3>
              <p>Please try refreshing and filling out the form again.</p>
            </div>
          </ModalContentContainer>
        </>
      )}

      <ContentContainer className="horizontal full">
        <ShippingAddressContainer>
          <ProgressBar steps={steps} currentStep={currentStep} />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <h1 className="desktop-only lighter-text">Shipping Address</h1>
            {lockerSelected && json?.data?.lockers && (
              <Button
                variant="text"
                size="small"
                onClick={() => {
                  openFindLockerModal();
                  setLearnMoreModalOpen(false);
                }}
                endIcon={<PencilIcon sx={{ width: 14, fill: "#6EADA8" }} />}
                className="desktop-only"
              >
                Change location
              </Button>
            )}
          </div>

          <hr className="desktop-only" />

          <OrderSummaryBox className="mobile-only" style={{ marginTop: "15px" }}>
            <h2>Order Summary</h2>
            <h3>{`${cart.items.length} item${cart.items.length !== 1 ? "s" : ""} in Cart.`}</h3>

            {cart?.items?.length > 0 &&
              Object.entries(cart.items).map(([itemId, itemData]) => (
                <CartItem key={`${itemId}-${itemData.id}`}>
                  <img
                    src={itemData.source}
                    alt={itemData.alt}
                    style={{
                      height: "81px",
                    }}
                  />
                  <CartItemInner>
                    <p>{itemData.title}</p>
                    <p>Qty: {itemData.quantity}</p>
                    <p>{formatCurrency(parseFloat(itemData.price.replace("$", "")) * itemData.quantity)}</p>
                  </CartItemInner>
                </CartItem>
              ))}
            <h5>Cart Subtotal</h5>
            <span className="subTotalAmount">{`${formatCurrency(subtotal)}`}</span>
          </OrderSummaryBox>

          <UspsLockerPopUpWrapper>
            <UspsLockerPopUpContent>
              <InfoIcon sx={{ minWidth: 30, fill: "#1E2854" }} />
              <p>
                USPS Smart Lockers let you ship packages to free, secure, self-service Smart lockers in select Post
                Office locations. If a locker is available near your shipping address entered below, you will be
                prompted to select a locker convenient to the recipient. (Recipient email address required){" "}
                <Link to="https://www.uspssmartpackagelockers.com/" target="_blank">
                  Learn More
                </Link>
                .
              </p>
            </UspsLockerPopUpContent>
          </UspsLockerPopUpWrapper>

          <div style={{ display: "flex" }}>
            <h1 className="mobile-only lighter-text" style={{ fontSize: "30px" }}>
              Shipping Address
            </h1>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                openFindLockerModal();
                setLearnMoreModalOpen(false);
              }}
              endIcon={<PencilIcon sx={{ width: 14, fill: "#6EADA8" }} />}
              className="mobile-only"
            >
              Change location{" "}
            </Button>
          </div>

          {lockerSelected && (
            <div
              style={{
                display: "flex",
                gap: 20,
                marginTop: 20,
              }}
              className="selected-locker-container"
            >
              <div className="selected-locker-address-container">
                <div>
                  <PinIcon sx={{ fill: "#0063BA", width: 43 }} />
                </div>
                <div>
                  <h5
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Customer pick up at:
                  </h5>
                  <ul>
                    <li>{addressData.pickupStreet}</li>
                    <li>
                      {addressData.pickupCity}, {addressData.pickupState} {addressData.pickupPostal}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="selected-locker-address-container">
                <div className="checkout-ship-to-address">
                  <h5
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                  >
                    Ship to:
                  </h5>
                  <ul>
                    <li>{addressData.shipAddress}</li>
                    <li>
                      {addressData.shipCity}, {addressData.shipState} {addressData.shipPostal}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          <form id="checkout-form" onSubmit={handleSubmit(onSubmit)}>
            <InputRow>
              <InputContainer width="50%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>First Name
                </p>
                <input type="text" id={"first-name"} {...register("firstName")} />
                <div>{validationErrors.firstName}</div>
              </InputContainer>

              <InputContainer width="50%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>Last Name
                </p>
                <input type="text" id={"last-name"} {...register("lastName")} />
                <div>{validationErrors.lastName}</div>
              </InputContainer>
            </InputRow>

            <InputRow>
              <InputContainer width="50%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>Email
                </p>
                <input type="text" id={"email"} {...register("email")} />
                <div>{validationErrors.email}</div>
              </InputContainer>
            </InputRow>

            <InputRow>
              <InputContainer width="70%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>Street Address
                </p>
                <input type="text" id={"street-address"} {...register("streetAddress")} />
                <div>{validationErrors.streetAddress}</div>
              </InputContainer>

              <InputContainer width="30%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>Country
                </p>
                <select id={"country"} {...register("country")}>
                  <option>United States</option>
                </select>
                <div>{validationErrors.country}</div>
              </InputContainer>
            </InputRow>

            <InputRow>
              <InputContainer width="15%">
                <p className="lighter-text">Apt. Number</p>
                <input type="text" id={"apt-number"} {...register("aptNumber")} />
                <div>{validationErrors.aptNumber}</div>
              </InputContainer>
              <InputContainer width="20">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>City
                </p>
                <input type="text" id={"city"} {...register("city")} />
                <div>{validationErrors.city}</div>
              </InputContainer>
              <InputContainer width="15%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>State/Province
                </p>
                <select id={"state"} {...register("state")} defaultValue="">
                  <option disabled value="">
                    -- Select --
                  </option>
                  {stateAbbreviations.map(state => {
                    return (
                      <option
                        key={state}
                        defaultValue={
                          addressData.shipState && state.toUpperCase() === addressData.shipState.toUpperCase()
                        }
                      >
                        {state}
                      </option>
                    );
                  })}
                </select>
                <div>{validationErrors.state}</div>
              </InputContainer>
              <InputContainer width="20%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>Zip/Postal Code
                </p>
                <input type="text" id={"zip"} {...register("zip")} />
                <div>{validationErrors.zip}</div>
              </InputContainer>
              <InputContainer width="30%">
                <p className="lighter-text">
                  <span style={{ color: "red" }}>*</span>Phone Number
                </p>
                {/* <input type="text" id={"phone"} {...register("phone")} /> */}
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <InputMask mask="(999) 999-9999" maskChar="" {...field} />}
                />
                <div>{validationErrors.phone} </div>
              </InputContainer>
            </InputRow>

            {currentStep === 2 && (
              <>
                <h2>Payment Methods</h2>
                <hr />
                <br />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 20,
                  }}
                >
                  <div>
                    <h3
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                      }}
                    >
                      {hideCCForm ? "Card on File:" : "Credit Card:"}
                    </h3>
                  </div>
                  <div
                    style={{
                      display: hideCCForm ? "none" : "flex",
                      gap: 20,
                      alignItems: "center",
                    }}
                  >
                    <input type="radio" name="ccOnFile" id="ccOnFile" defaultChecked />
                    <label htmlFor="ccOnFile">Card on file</label>
                    <CCContainer>
                      <Link to="" onClick={() => alert('This is a mock cart. Click "Place Order".')}>
                        <img src={applePay} width="100%" alt="Apple Pay" />
                      </Link>
                    </CCContainer>
                    <CCContainer>
                      <Link to="" onClick={() => alert('This is a mock cart. Click "Place Order".')}>
                        <img src={googlePay} width="100%" alt="G-Pay" />
                      </Link>
                    </CCContainer>
                  </div>
                </div>

                <div
                  style={{
                    display: hideCCForm ? "block" : "none",
                    marginTop: "1rem",
                  }}
                  className="lighter-text"
                >
                  Card ending: **** **** **** 1111
                </div>

                <div
                  style={{
                    display: hideCCForm ? "none" : "block",
                  }}
                >
                  <InputRow>
                    <InputContainer width="100%">
                      <p>
                        <span style={{ color: "red" }}>*</span>Card Number
                      </p>
                      <Controller
                        name="cardNumber"
                        control={control}
                        value=""
                        render={({ field }) => (
                          <InputMask mask="9999 9999 9999 9999" maskChar="" readOnly {...field}>
                            {inputProps => <input {...inputProps} />}
                          </InputMask>
                        )}
                      />
                      <div>{errors.cardNumber?.message}</div>
                    </InputContainer>
                  </InputRow>
                  <InputRow>
                    <InputContainer width="50%">
                      <p>
                        <span style={{ color: "red" }}>*</span>Expiration Date (MM/YY)
                      </p>
                      <Controller
                        name="expirationDate"
                        value=""
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            placeholder="MM/YY"
                            readOnly
                            maxLength={5}
                            // onChange={(e) => {
                            //   // Transform the input value before updating the field
                            //   const transformedValue = formatExpiryDate(
                            //     e.target.value
                            //   );
                            //   field.onChange(transformedValue);
                            // }}
                          />
                        )}
                      />
                      <div>{errors.expirationDate?.message}</div>
                    </InputContainer>
                    <InputContainer width="50%">
                      <p>
                        <span style={{ color: "red" }}>*</span>Security Code
                      </p>
                      <Controller
                        name="securityCode"
                        value=""
                        control={control}
                        render={({ field }) => (
                          <input
                            maxLength={3}
                            readOnly
                            {...field}
                            // onChange={(e) => {
                            //   field.onChange(e.target.value);
                            // }}
                          />
                        )}
                      />
                      <div>{errors.securityCode?.message}</div>
                    </InputContainer>
                  </InputRow>
                  <InputRow>
                    <InputContainer width="100%">
                      <p>
                        <span style={{ color: "red" }}>*</span>Name on card
                      </p>
                      <Controller
                        name="name"
                        value=""
                        control={control}
                        render={({ field }) => <input {...field} readOnly />}
                      />
                      <div>{errors.name?.message}</div>
                    </InputContainer>
                  </InputRow>
                </div>
              </>
            )}
          </form>
        </ShippingAddressContainer>
        <RightContainer>
          <OrderSummaryBox className="desktop-only">
            <h2>Order Summary</h2>
            <h3>{`${cart.items.length} item${cart.items.length !== 1 ? "s" : ""} in Cart.`}</h3>

            <hr />

            <br />

            {cart?.items?.length > 0 &&
              Object.entries(cart.items).map(([itemId, itemData]) => (
                <CartItem key={`${itemId}-${itemData.id}`}>
                  <img
                    src={itemData.source}
                    alt={itemData.alt}
                    style={{
                      height: 150 + "px",
                    }}
                  />
                  <CartItemInner>
                    <p>{itemData.title}</p>
                    <p>Qty: {itemData.quantity}</p>
                    <p>{formatCurrency(parseFloat(itemData.price.replace("$", "")) * itemData.quantity)}</p>
                  </CartItemInner>
                </CartItem>
              ))}
            <hr />
            <h5>Cart Subtotal</h5>
            <span className="subTotalAmount">{`${formatCurrency(subtotal)}`}</span>
          </OrderSummaryBox>

          <br />

          <Button
            // disabled={!isValid}
            type={currentStep === 1 ? "button" : "submit"}
            form="checkout-form"
            onClick={handleFirstStepCompletion}
            className="mobile-full-width"
          >
            {currentStep === 1 ? "Next" : "Place Order"}
          </Button>
        </RightContainer>
      </ContentContainer>
    </Container>
  );
}

export default Checkout;
