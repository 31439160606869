const API_URL = "https://dev.uspssmartpackagelockers.com/services/api/v1";
const REDIRECT_URL = "https://www.authentikadventures.com/redirect";
const REDIRECT_ORIGIN = "https://www.authentikadventures.com";

/**
 * Allows access to USPS Smart Lockers.
 *
 * ```
 * const lockers = new UspsLockers(apiKey)
 * const available = await lockers.lockersAvailable("12345", 20);
 * let removeLockerListener = null;
 *
 * if (available) {
 *     removeLockerListener = lockers.addOnLockerSelectedListener((locker) => {
 *         console.log(locker);
 *     });
 * }
 *
 * // ...
 * // on clean up
 * if (removeLockerListener) {
 *     removeLockerListener();
 * }
 * ```
 */
export class UspsLockers {
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  /**
   * Determines if there are any lockers available within the specified ZIP5 and the given radius, in miles.
   * @param {string} zip5 5-digit postal ZIP5 string.
   * @param {int} radius radius of the check, in miles.
   * @returns {boolean} `true` if lockers are available; `false` otherwise.
   */
  async lockersAvailable(zip5, radius = 20) {
    const headers = new Headers();
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    headers.append("x-api-key", this.apiKey);
    const response = await fetch(`${API_URL}/hasAvailableLockers`, {
      method: "POST",
      body: JSON.stringify({ "zip-code": zip5, radius: `${radius}` }),
      headers,
    });

    if (!response.ok) {
      throw new Error(
        `Unable to fetch lockers availability: ${response.status}`
      );
    }

    const json = await response.json();
    return json.available;
  }

  /**
   * Opens a new window popup, that allows the user to select a locker to ship to. Ensure you checked {@link lockersAvailable} first!
   * @param {string} zip5 5-digit postal ZIP5 string.
   * @param {int} radius radius of the check, in miles.
   */
  openLockerSelectionWindow(zip5, radius = 20) {
    window.open(
      `${REDIRECT_URL}?zip5=${zip5}&radius=${radius}&origin=${window.location.origin}`,
      "_blank",
      "popup=true,width=1186"
    );
  }

  /**
   * Adds a listener for when a user selects a Smart Locker on the popup window.
   *
   * ```
   * const removeListener = lockers.addOnLockerSelectedListener((locker) => {
   *    console.log(locker);
   * });
   * // ...
   * // on clean up
   * removeListener()
   *
   * ```
   * @param {(locker) => void} callback the callback that receives a locker object for the selected Smart Locker.
   * @returns {() => void)} a function that can be invoked to remvove the listener.
   */
  addOnLockerSelectedListener(callback) {
    const windowListener = (event) => {
      if (event.origin !== REDIRECT_ORIGIN) return;
      if (event.data) {
        callback(event.data);
      }
    };
    window.addEventListener("message", windowListener, false);
    return () => {
      window.removeEventListener("message", windowListener, false);
    };
  }
}
