import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    background-color: #e7f6f8;
    border-left: 15px solid #3573b1;
    display: flex;
    margin-top: 20px;
`;

const UspsLockerPopUpWrapper = (props) => {
    return <Wrapper>{props.children}</Wrapper>;
};

export default UspsLockerPopUpWrapper;

