import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import React from "react";
import mapPin from "../static/img/map-pin.png";
import greenMapPin from "../static/img/green-map-pin.png";
import redMapPin from "../static/img/red-map-pin.png";
import grayMapPin from "../static/img/gray-map-pin.png";
import uspsMapPin from "../static/img/usps-map-pin.png";
import PropTypes from "prop-types";

const containerStyle = {
  height: "826px",
  width: "1034px",
};

const mobileContainerStyle = {
  height: "100%",
  width: "100%",
};

const mapPinAssign = location => {
  if (location.lockerStatus === "Operational") {
    return greenMapPin;
  } else if (location.lockerStatus === "Not Configured") {
    return uspsMapPin;
  } else if (location.lockerStatus === "Not Operational") {
    return redMapPin;
  } else if (location.lockerStatus === ("In Maintenance" || "Coming Soon")) {
    return grayMapPin;
  } else return mapPin;
};

const GoogleMapsProps = {
  lockerList: PropTypes.object,
  mobile: PropTypes.bool,
  mapCenter: PropTypes.object,
  zoom: PropTypes.number,
  noStatus: PropTypes.bool,
  noCount: PropTypes.bool,
};

export const GoogleMaps = props => {
  const { lockerList, mobile, mapCenter, zoom, noStatus, noCount } = props;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  // eslint-disable-next-line no-unused-vars
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    map.setZoom(zoom);

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  if (isLoaded) {
    if (lockerList && lockerList.data !== null) {
      return (
        <GoogleMap
          mapContainerStyle={mobile ? mobileContainerStyle : containerStyle}
          center={mapCenter}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {lockerList.data.lockers?.map((location, index) => {
            return (
              <MarkerF
                icon={!noStatus ? mapPinAssign(location) : mapPinAssign("")}
                key={location.facilityID}
                position={{
                  lat: parseFloat(Number(location.locationLatitude)),
                  lng: parseFloat(Number(location.locationLongitude)),
                }}
                label={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "normal",
                  text: !noCount ? (index + 1).toString() : " ",
                }}
              />
            );
          })}
        </GoogleMap>
      );
    } else {
      return (
        <GoogleMap
          mapContainerStyle={mobile ? mobileContainerStyle : containerStyle}
          center={mapCenter}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      );
    }
  } else return <></>;
};

GoogleMaps.propTypes = GoogleMapsProps;

export default GoogleMaps;
